.timeline{
    padding: 2.0%;
    display:block;
    position:absolute;
    top: 7.5%;
    left: 12.5%;
    color: var(--textblack);
    text-align: center;
    width: 75%;
    height:81.5%;
    background-color: rgb(100%, 100%, 100%, 0.8);
    border-radius: 20px;
}
.abtText {
    font-size:21px;
    color: rgb(0, 0%, 0%, 0.84);
    font-family:"Roboto";
}

.abtTitle {
    margin-top: 20px;
    margin-bottom:0;
    font-family: "Bebas Neue", cursive;
    font-size: 5rem;
    letter-spacing: 3px;
}

.abtTitle2{
    margin:10px;
    font-family: 'Roboto', Arial, sans-serif;
    text-transform: uppercase;
    font-weight:700;
    letter-spacing: 0.02em;
    color: #f5f5f5;
    font-size: 5rem;
    text-shadow: 0.015em 0.015em 0.015em rgba(0, 0, 0, 0.3);
}

.abtTitle2:after {
    content: attr(aria-label);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    text-shadow: 0.01em 0.01em 0.01em rgba(0, 0, 0, 0.3);
}

.blocks {
    position:relative;
    display:inline-flex;
    width:100%;
    height:100%;
}

.block1 {
    display:inline-block;
    padding:3%;
    width:30%;
    height:75%;

    background-color: rgb(100%, 100%, 100%);
    border-radius: 10px;
}

.block2 {
    display:inline-block;
    padding:3%;
    margin-left: 3%;
    width:30%;
    height:75%;
    background-color: rgb(100%, 100%, 100%);
    border-radius: 10px;
}

.block3{
    display:inline-block;
    padding:3%;
    margin-left: 3%;
    width:30%;
    height:75%;
    background-color: rgb(100%, 100%, 100%);
    border-radius: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap');

.blockTitle {
    margin-top: 10px;
    margin-bottom:0;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 1px;
    font-style: normal;
    font-size: 2rem;
}

@media (max-width: 700px) {
    .timeline{
        padding: 2.0%;
        padding-bottom: none;
        display:block;
        position:absolute;
        top: 7.5%;
        left: 1.75%;
        color: var(--textblack);
        text-align: center;
        width: 92.5%;
        height:81.5%;
        background-color: rgb(100%, 100%, 100%, 0.8);
        border-radius: 20px;
    }
    .abtText {
        font-size:18px;
        color: rgb(0, 0%, 0%, 0.84);
        font-family:"Roboto";
    }
    
    .abtTitle {
        margin-top: 16px;
        margin-bottom:0;
        font-family: "Bebas Neue", cursive;
        font-size: 3rem;
        letter-spacing: 3px;
    }
    
    .abtTitle2{
        margin:10px;
        font-family: 'Roboto', Arial, sans-serif;
        text-transform: uppercase;
        font-weight:700;
        letter-spacing: 0.02em;
        color: #f5f5f5;
        font-size: 3rem;
        text-shadow: 0.015em 0.015em 0.015em rgba(0, 0, 0, 0.3);
    }
    
    .abtTitle2:after {
        content: attr(aria-label);
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        text-shadow: 0.01em 0.01em 0.01em rgba(0, 0, 0, 0.3);
    }
    
    .blocks {
        position:relative;
        display:inline-flex;
        width:100%;
        height:100%;
    }
    
    .block1 {
        display:inline-block;
        padding:3%;
        width:30%;
        height:85%;
    
        background-color: rgb(100%, 100%, 100%);
        border-radius: 10px;
    }
    
    .block2 {
        display:inline-block;
        padding:3%;
        margin-left: 3%;
        width:30%;
        height:85%;
        background-color: rgb(100%, 100%, 100%);
        border-radius: 10px;
    }
    
    .block3{
        display:inline-block;
        padding:3%;
        margin-left: 3%;
        width:30%;
        height:85%;
        background-color: rgb(100%, 100%, 100%);
        border-radius: 10px;
    }
    
    @import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200..700&display=swap');
    
    .blockTitle {
        margin-top: 10px;
        margin-bottom:0;
        font-family: 'Bebas Neue', cursive;
        letter-spacing: 1px;
        font-style: normal;
        font-size: 1.5rem;
    }
}