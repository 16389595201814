/* Main Contact Section */
.contact-us-main {
    scroll-behavior: smooth;
    font-family: "Roboto";
    position:absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
}

/* Contact Form Container */
.contact-us-section {
    font-family: "Roboto";
    background: white;
    position: absolute;
    right:25%;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 50%;
    text-align: center;
}

/* Title and Info Section */
.contact-us-section h1 {
    font-family: "Bebas Neue", cursive;
    margin-bottom: 20px;
    color: #333;
    font-size: 4rem;
    letter-spacing: 5px;
    font-weight: 600;
}

.contact-info p {
    font-family: "Roboto";
    font-size: 18px;
    margin: 10px 0;
    color: #666;
}

.additionalInfo {
    font-size:18px;
    font-family: "Roboto";
}

.contact-info hr {
    width: 120px;
    height: 5px;
    border: none;
    background-color: #6c757d;
    margin-bottom: 20px;
    border-radius: 10px;
}

/* Social Links */
.social-links {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-links li {
    display: inline;
}

.social-links a {
    color: #666;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
}

.social-links a:hover {
    color: #333;
}

/* Form Styling */
.contact-form {
    margin-top: 20px;
    width:100%;
    text-align: left;
}

.contact-form label {
    font-family: "Bebas Neue", cursive;
    letter-spacing: 1.5px;
    font-size: 1.35rem;
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.contact-form textarea, .contact-form input {
    font-family: "Roboto";
    width: 99%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f1f3f5;
    outline: none;
}

.contact-form input:focus,
.contact-form textarea:focus {
    font-family: "Roboto";
    border: 2px solid #6c757d;
}

.contact-form textarea {
    font-family: "Roboto";
    height: 140px;
    padding-top: 15px;
    border-radius: 20px;
}

/* Button Styling */
.contact-form button {
    background-color: #fff;
    border: 0 solid #e2e8f0;
    border-radius: 2rem;
    box-sizing: border-box;
    color: #0d172a;
    cursor: pointer;
    display: inline-block;
    font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1;
    padding: 1rem 1.6rem;
    text-align: center;
    text-decoration: none #0d172a solid;
    text-decoration-thickness: auto;
    transition: all .1s;
    box-shadow: 0.5px 3px 4px rgba(166, 175, 195, 0.5);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.contact-form button:hover {
    background-color: #1e293b;
    color: #fff;
}

button img {
    height: 15px;
}

/* Responsive Adjustments */
@media (max-width: 800px) {
    /* Contact Form Container */
    .contact-us-section {
        font-family: "Roboto";
        background: white;
        position: absolute;
        left:1%;
        right:0;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 85%;
        text-align: center;
    }

    .contact-form input,
    .contact-form textarea {
        width: 90%;
    }

    .contact-right {
        display: none;
    }
}


/* Social Links */
.social-links {
    list-style: none;
    padding: 0;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.social-links li {
    display: inline;
}

.social-links a {
    position: relative;
    padding-left: 30px;
    color: #666;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s;
}

.social-links a:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Brands";
    font-size: 20px;
    color: #666;
}

.social-links a:hover {
    color: #333;
}

/* Facebook Icon */
.social-links .facebook-link:before {
    content: "\f09a";
}

/* Twitter Icon */
.social-links .twitter-link:before {
    content: "\f099"; 
}

/* LinkedIn Icon */
.social-links .linkedin-link:before {
    content: "\f0e1"; 
}

.pageBanner {
    width: 100%;
    height:auto;
}
  
contact a {
    display:inline-block;
    position:relative;
    width:100px;
    height:100px;
    line-height:100px;
    font-size:40px;
    text-align:center;
    text-decoration:none;
    color:#404040;
    transition:.5s;
}

contact a span {
    position:absolute;
    transition: transform .5s;
}
contact a span:nth-child(1),
contact a span:nth-child(3){
    width:100%;
    height:3px;
    background:#404040;
}
contact a span:nth-child(1) {
    top:0;
    left:0;
    transform-origin: right;
}
contact a:hover span:nth-child(1) {
    transform: scaleX(0);
    transform-origin: left;
    transition:transform .5s;
}

contact a span:nth-child(3) {
    bottom:0;
    left:0;
    transform-origin: left;
}
contact a:hover span:nth-child(3) {
    transform: scaleX(0);
    transform-origin: right;
    transition:transform .5s;
}

contact a span:nth-child(2),
contact a span:nth-child(4){
    width:3px;
    height:100%;
    background:#404040;
}
contact a span:nth-child(2) {
    top:0;
    left:0;
    transform:scale(0);
    transform-origin: bottom;
}
contact a:hover span:nth-child(2) {
    transform: scale(1);
    transform-origin: top;
    transition:transform .5s;
}
contact a span:nth-child(4) {
    top:0;
    right:0;
    transform:scale(0);
    transform-origin: top;
}
contact a:hover span:nth-child(4) {
    transform: scale(1);
    transform-origin: bottom;
    transition:transform .5s;
}

.instagram:hover {
    color: #c32aa3;
}

.instagram:hover span { 
    background: #c32aa3;
}

contact a:hover:nth-child(3) {
    color: #c32aa3;
}
contact a:hover:nth-child(4) {
    color: #dd4b39;
}

header {
    margin:0;
    display:block;
    position: fixed;
    width: 100%;
    background: #ffffff;
    color: var(--textblack);
    z-index: 1000;
}