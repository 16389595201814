.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background */
  }
  
  .modal-content {
    background-color: #fff;
    padding: 2.5%;
    border-radius: 10px;
    width: auto; 
    height: auto;
    max-width: 35%; 
    max-height: 80%;  
    overflow-y: hidden; 
  }
  
  .modal-image {
    max-width:100%;
    max-height: 100%;
    border-radius: 5px;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #ccc;
  }

  @media (max-width:700px) {
    .modal-content {
      background-color: #fff;
      padding: 2.5%;
      border-radius: 10px;
      width: auto; 
      height: auto;
      max-width: 80%; 
      max-height: 80%;  
      overflow-y: hidden; 
    }
    .modal-image {
      max-width:100%;
      max-height: auto;
      border-radius: 5px;
    }
  }